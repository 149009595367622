var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dp-pro-recipe" },
    [
      _vm.isLoading || !_vm.isFetched
        ? _c("DpPageLoading", {
            attrs: { "is-loading": _vm.isLoading || !_vm.isFetched },
          })
        : _vm._e(),
      _c(
        "div",
        { style: { opacity: !_vm.isLoading ? 1 : 0 } },
        [
          _vm.selectedCapsule
            ? [
                _c("div", { staticClass: "dp-pro-recipe-selected" }, [
                  _c("h2", { staticClass: "dp-pro-recipe-selected__title" }, [
                    _vm._v(
                      " " + _vm._s(_vm.WORDS.DRIP_POD_PRO_RECIPE.SELECTED) + " "
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "dp-pro-recipe-selected__content" },
                    [
                      _c("Icon", {
                        attrs: {
                          name: "dpRadioButtonOn",
                          color: "dpGrey88",
                          width: 16,
                          height: 16,
                        },
                      }),
                      _vm.selectedCapsule.image_url
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "dp-pro-recipe-selected__content-thumbnail",
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: _vm.selectedCapsule.image_url,
                                  alt: _vm.selectedCapsule.name,
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _c("div", [
                        _vm.selectedCapsule.name
                          ? _c(
                              "h3",
                              {
                                staticClass:
                                  "dp-pro-recipe-selected__content-title",
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.selectedCapsule.name) + " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.selectedCapsule.description
                          ? _c(
                              "p",
                              {
                                staticClass:
                                  "dp-pro-recipe-selected__content-description",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.selectedCapsule.description) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.selectedCapsuleEcUrl
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "dp-pro-recipe-selected__content-button",
                              },
                              [
                                _vm.selectedCapsuleEcUrl
                                  ? _c(
                                      "DpTextButton",
                                      {
                                        attrs: {
                                          href: _vm.selectedCapsuleEcUrl,
                                          size: "sm",
                                          align: "left",
                                          fluid: "",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "iconAppend",
                                              fn: function () {
                                                return [
                                                  _c("Icon", {
                                                    attrs: {
                                                      name: "dpArrowRightB",
                                                      color: "dpBlack01",
                                                    },
                                                  }),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          3194498299
                                        ),
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.WORDS.DRIP_POD_PRO_RECIPE
                                                .STORE_LINK_CAPSULE
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                ]),
                _c("h2", { staticClass: "dp-pro-recipe-list-title" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.WORDS.DRIP_POD_PRO_RECIPE.SELECTED_CAPSULE_RECIPE
                      ) +
                      " "
                  ),
                ]),
              ]
            : _vm._e(),
          _vm.proRecipeContents && _vm.proRecipeContents.length
            ? [
                _c(
                  "div",
                  { staticClass: "dp-pro-recipe-list-content" },
                  _vm._l(_vm.proRecipeContents, function (content) {
                    return _c("DpProRecipeCard", {
                      key: content.index,
                      attrs: { content: content },
                      on: { "on-img-loaded": _vm.onCardImgLoaded },
                    })
                  }),
                  1
                ),
              ]
            : _vm.proRecipeContents && _vm.proRecipeContents.length === 0
            ? _c(
                "div",
                { staticClass: "dp-pro-recipe-empty" },
                [
                  _c("DpEmpty", {
                    attrs: {
                      "icon-name": "recipe",
                      text: _vm.WORDS.DRIP_POD_PRO_RECIPE.EMPTY_TEXT,
                      subtext: _vm.selectedCapsule
                        ? _vm.WORDS.DRIP_POD_PRO_RECIPE.EMPTY_SUBTEXT
                        : "",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }