<template>
  <div class="dp-pro-recipe">
    <DpPageLoading
      v-if="isLoading || !isFetched"
      :is-loading="isLoading || !isFetched" />

    <div :style="{ opacity: !isLoading ? 1 : 0 }">
      <template v-if="selectedCapsule">
        <div class="dp-pro-recipe-selected">
          <h2 class="dp-pro-recipe-selected__title">
            {{ WORDS.DRIP_POD_PRO_RECIPE.SELECTED }}
          </h2>

          <div class="dp-pro-recipe-selected__content">
            <Icon
              name="dpRadioButtonOn"
              color="dpGrey88"
              :width="16"
              :height="16" />

            <div
              v-if="selectedCapsule.image_url"
              class="dp-pro-recipe-selected__content-thumbnail">
              <img
                :src="selectedCapsule.image_url"
                :alt="selectedCapsule.name" />
            </div>

            <div>
              <h3
                v-if="selectedCapsule.name"
                class="dp-pro-recipe-selected__content-title">
                {{ selectedCapsule.name }}
              </h3>
              <p
                v-if="selectedCapsule.description"
                class="dp-pro-recipe-selected__content-description">
                {{ selectedCapsule.description }}
              </p>
              <div
                v-if="selectedCapsuleEcUrl"
                class="dp-pro-recipe-selected__content-button">
                <DpTextButton
                  v-if="selectedCapsuleEcUrl"
                  :href="selectedCapsuleEcUrl"
                  size="sm"
                  align="left"
                  fluid>
                  <template v-slot:iconAppend>
                    <Icon name="dpArrowRightB" color="dpBlack01" />
                  </template>
                  {{ WORDS.DRIP_POD_PRO_RECIPE.STORE_LINK_CAPSULE }}
                </DpTextButton>
              </div>
            </div>
          </div>
        </div>

        <h2 class="dp-pro-recipe-list-title">
          {{ WORDS.DRIP_POD_PRO_RECIPE.SELECTED_CAPSULE_RECIPE }}
        </h2>
      </template>

      <template v-if="proRecipeContents && proRecipeContents.length">
        <div class="dp-pro-recipe-list-content">
          <DpProRecipeCard
            v-for="content in proRecipeContents"
            :key="content.index"
            :content="content"
            @on-img-loaded="onCardImgLoaded" />
        </div>
      </template>

      <div
        v-else-if="proRecipeContents && proRecipeContents.length === 0"
        class="dp-pro-recipe-empty">
        <DpEmpty
          icon-name="recipe"
          :text="WORDS.DRIP_POD_PRO_RECIPE.EMPTY_TEXT"
          :subtext="
            selectedCapsule ? WORDS.DRIP_POD_PRO_RECIPE.EMPTY_SUBTEXT : ''
          " />
      </div>
    </div>
  </div>
</template>

<script>
import { WORDS } from '@/constants/words';
import { SystemDialogMessage } from '@/constants/enums';
import {
  defineComponent,
  ref,
  computed,
  watch,
  onMounted,
  inject
} from '@vue/composition-api';
import { usePublishable } from '@/composables/usePublishable';

export default defineComponent({
  components: {
    DpPageLoading: () => import('@/components/dripPod/core/PageLoading.vue'),
    DpProRecipeCard: () => import('@/components/dripPod/proRecipe/Card.vue'),
    DpTextButton: () => import('@/components/dripPod/core/TextButton.vue'),
    DpEmpty: () => import('@/components/dripPod/core/Empty.vue')
  },

  beforeRouteEnter(to, from, next) {
    next(() => {
      if (!from.name) return;

      // プロレシピ詳細からの遷移時は何もしない
      if (from.name === 'DripPodProRecipeDetail') return;

      switch (to.name) {
        case 'DripPodProRecipe':
          window.localStorage.setItem('BackFromProRecipe', from.path);
          break;

        case 'DripPodProRecipeByCapsuleId':
          window.localStorage.setItem(
            'BackFromProRecipeByCapsuleId',
            from.path
          );
          break;
      }
    });
  },

  beforeRouteLeave(to, from, next) {
    next(() => {
      if (!to.name) return;

      if (to.name !== 'DripPodProRecipeDetail') {
        switch (from.name) {
          case 'DripPodProRecipe':
            window.localStorage.removeItem('BackFromProRecipe');
            break;

          case 'DripPodProRecipeByCapsuleId':
            window.localStorage.removeItem('BackFromProRecipeByCapsuleId');
            break;

          default:
            break;
        }
      }
    });
  },

  setup: (props, context) => {
    const { getPublishableContents } = usePublishable();
    const setHeaderBackTo = inject('setHeaderBackTo');
    const proRecipeContents = ref([]);
    const selectedCapsule = ref(undefined);
    const selectedCapsuleRecipes = ref(undefined);
    const loadedImgCount = ref(0);
    const isFetched = ref(false);

    const capsuleId = computed(
      () => Number(context.root.$route.params.capsuleId) || ''
    );

    /* eslint-disable indent */
    const selectedCapsuleEcUrl = computed(() =>
      selectedCapsule.value?.link_url
        ? context.root.$customUrlScheme.page(
            'ec',
            `url=${selectedCapsule.value.link_url}`
          )
        : false
    );
    /* eslint-enable */

    const isLoading = computed(() =>
      proRecipeContents.value && proRecipeContents.value.length
        ? loadedImgCount.value !== proRecipeContents.value.length
        : false
    );

    const onCardImgLoaded = () => {
      loadedImgCount.value++;
    };

    const fetchData = async () => {
      context.root.$_loading_start();

      await fetchSelectedCapsule();
      await fetchRecipeList();

      context.root.$_loading_stop();
      isFetched.value = true;
    };

    const fetchRecipeList = async () => {
      try {
        const { data } = await context.root
          .$repositories('dpProRecipe')
          .getList();

        const publishableContents = await getPublishableContents(data);
        if (capsuleId.value) {
          if (!selectedCapsule.value?.recipes) return;

          selectedCapsuleRecipes.value = await getPublishableContents(
            selectedCapsule.value?.recipes
          );

          proRecipeContents.value = await selectedCapsuleRecipes.value?.map(
            (recipe) => {
              const selectedRecipe = publishableContents.find(
                (content) => content.id === recipe.id
              );
              return {
                ...selectedRecipe
              };
            }
          );
        } else {
          proRecipeContents.value = await publishableContents;
        }
      } catch {
        window.location.href = context.root.$systemDialog(
          SystemDialogMessage.ERROR_COMMON
        );
      }
    };

    const fetchSelectedCapsule = async () => {
      if (!capsuleId.value) return;
      try {
        const { data } = await context.root
          .$repositories('dpCapsule')
          .getById(capsuleId.value);
        selectedCapsule.value = data;
      } catch {
        window.location.href = context.root.$systemDialog(
          SystemDialogMessage.ERROR_COMMON
        );
      }
    };

    watch(
      () => context.root.$route.name,
      async () => {
        await fetchData();
      }
    );

    fetchData();

    onMounted(async () => {
      switch (context.root.$route.name) {
        case 'DripPodProRecipe':
          setHeaderBackTo(window.localStorage.getItem('BackFromProRecipe'));
          break;

        case 'DripPodProRecipeByCapsuleId':
          setHeaderBackTo(
            window.localStorage.getItem('BackFromProRecipeByCapsuleId')
          );
          break;

        default:
          break;
      }
    });

    return {
      WORDS,
      proRecipeContents,
      selectedCapsule,
      selectedCapsuleEcUrl,
      onCardImgLoaded,
      isLoading,
      isFetched
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.dp-pro-recipe {
  @include mixin.dpDefaultText;

  background-color: variables.$dpWhite01;
}

.dp-pro-recipe-selected {
  margin-bottom: 16px;
  padding: 16px;

  &__title {
    @include mixin.dpEnTitle;

    margin-bottom: 8px;
    font-size: 24px;
  }

  &__content {
    display: grid;
    grid: auto-flow / 16px 22% 1fr;
    place-items: center;
    gap: 16px;
    padding: 16px;
    background-color: variables.$dpWhite01;
    border: 1px solid variables.$dpGreyCc;

    > * {
      > * {
        &:last-child {
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }
    }

    &-thumbnail {
      @include mixin.imageAspectRatio(160, 160);

      img {
        object-fit: contain;
      }
    }

    &-title {
      margin-bottom: 8px;
      font-size: 14px;
      font-weight: bold;
    }

    &-description {
      margin-bottom: 0;
      color: variables.$dpGrey66;
      font-size: 12px;
    }

    &-button {
      > * {
        padding-bottom: 0 !important;
      }
    }
  }
}

.dp-pro-recipe-list-title {
  margin: 0 16px 16px;
  font-size: 18px;
  font-weight: bold;
}

.dp-pro-recipe-list-content {
  border-top: 1px solid variables.$dpGreyEa;
}

.dp-pro-recipe-empty {
  padding: 24px 16px 16px;
  background-color: variables.$dpWhite01;
  border-top: 1px solid variables.$dpGreyEa;
}
</style>
